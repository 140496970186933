import { Box, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import GenericPrelimHero from '../../OfferPageComponents/PrelimOfferPageComponents/GenericPrelimHeroSection/GenericPrelimHero'
import GenericPrelimImportantResources from '../../OfferPageComponents/PrelimOfferPageComponents/GenericPrelimImportantResourcesSection/GenericPrelimImportantResources'
import { useInView } from '../../utils'
import { OfferPageData } from '../offerPageTypes'
import PrelimNeighborhoodSection from './PrelimNeighborhoodSection'
import PrelimOfferNav from './PrelimOfferNav'
import PrelimOfferSection from './PrelimOfferSection'
import PrelimPortfolioSection from './PrelimPortfolioSection'
import PrelimStoriesSection from './PrelimStoriesSection'

type PreliminaryOfferPageProps = {
  pageData: OfferPageData
}

const PreliminaryOfferPage = (props: PreliminaryOfferPageProps) => {
  const { pageData } = props

  const [shouldHideNeighbors, setShouldHideNeighbors] = useState(false)

  const hideNeighborsFn = useCallback(() => setShouldHideNeighbors(true), [])

  const {
    fullName,
    street,
    city,
    state,
    latitude,
    longitude,
    bedrooms,
    bathrooms,
    halfBaths,
    slackThreadUrl,
    staticDocumentOverrides,
    customerDocuments,
    customerUuid,
    propertyTypeDisplayString,
  } = pageData

  const currentSection = useInView([
    'offer',
    'neighbors',
    'portfolio',
    'stories',
    'next-steps',
    'important-resources',
  ])

  return (
    <>
      <Box
        height={{
          xs: '0px',
          sm: '40px',
          md: '40px',
        }}
      />
      <Box
        position="sticky"
        top={{ xs: '0px', sm: '0px', md: '0px' }}
        zIndex={2}
      >
        <PrelimOfferNav
          currentSection={currentSection}
          shouldHideNeighbors={shouldHideNeighbors}
          isGeneric
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={{
          xs: '-32px',
          md: '-16px',
        }}
      >
        <GenericPrelimHero fullName={fullName} />

        {!shouldHideNeighbors && (
          <PrelimNeighborhoodSection
            street={street}
            city={city}
            state={state}
            propertyType={propertyTypeDisplayString}
            bedrooms={bedrooms}
            bathrooms={bathrooms + (halfBaths / 2 || 0)}
            latitude={latitude}
            longitude={longitude}
            hideNeighbors={hideNeighborsFn}
          />
        )}
        <PrelimPortfolioSection isGeneric />
        <PrelimStoriesSection isGeneric />
        <GenericPrelimImportantResources
          userId={customerUuid}
          slackThreadUrl={slackThreadUrl}
          staticDocumentOverrides={staticDocumentOverrides}
          leadDocuments={customerDocuments}
        />
        <PrelimOfferSection sectionId="">
          <Typography variant="p4" color="gray5.main">
            As of 11/1/24. Annualized revenue is based on Fund financials for
            September 2024.
          </Typography>
        </PrelimOfferSection>
      </Box>
    </>
  )
}

export default PreliminaryOfferPage
